<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <a href="http://www.sci.hkbu.edu.hk/eng" target="_blank">
        <img
          class="m-4"
          src="/images/scilogo.png"
          alt="science faculty"
          width="150"
        />
      </a>
      <a href="http://ioc.hkbu.edu.hk/index.html" target="_blank">
        <img
          class="m-4"
          src="/images/IOC.jpg"
          alt="Institute of creative"
          width="90"
      /></a>
      <p>
        <strong>Sponsored by Hung Hin Shiu Charitable Foundation</strong>
      </p>
      <p><strong>© {{year}} ICTS. All rights reserved.</strong></p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "SiteFooter",
  props: {
    year: String
  }
};
</script>