import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import Oruga from '@oruga-ui/oruga-next'
import { bulmaConfig } from '@oruga-ui/theme-bulma'

import '@oruga-ui/theme-bulma/dist/bulma.css'
import { VueShowdownPlugin } from 'vue-showdown';

createApp(App).use(router).use(Oruga, bulmaConfig).use(VueShowdownPlugin, {
    // set default flavor of showdown
    flavor: 'github',
    // set default options of showdown (will override the flavor options)
    options: {
        emoji: false,
    },
}).mount('#app')
