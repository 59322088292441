<template>
  <div class="card is-shadowless columns my-4 is-vcentered is-multiline">
    <div class="card-image column is-one-third-desktop is-full-tablet">
      <figure class="image is-5by3 shadow">
        <router-link :to="'/event/' + event.title">
          <img
            :src="event.image || '/images/event.jpg'"
            alt="Placeholder image"
            style="object-fit: cover"
        /></router-link>
      </figure>
    </div>
    <div class="column is-two-third-desktop">
      <div class="card-content">
        <div class="media">
          <div class="media-content">
            <p class="title is-4">
              <router-link :to="'/event/' + event.title">
                <span v-if="isUpcoming == 'true'">[Upcoming] </span>{{ event.title }}
              </router-link>
            </p>
            <p class="subtitle is-6">
              {{ event.date }}
            </p>
          </div>
        </div>

        <div class="content text has-text-justified">
          <div
            v-html="event.content"
            class="content text has-text-justified is-clipped"
            :style="'text-overflow: ellipsis; height:' + textHeight"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EventCard",
  props: {
    event: Object,
    textHeight: String,
    isUpcoming: String
  },
};
</script>